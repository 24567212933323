// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-auth-callback-js": () => import("./../../../src/pages/auth/callback.js" /* webpackChunkName: "component---src-pages-auth-callback-js" */),
  "component---src-pages-members-js": () => import("./../../../src/pages/members.js" /* webpackChunkName: "component---src-pages-members-js" */),
  "component---src-templates-de-js": () => import("./../../../src/templates/de.js" /* webpackChunkName: "component---src-templates-de-js" */),
  "component---src-templates-docs-js": () => import("./../../../src/templates/docs.js" /* webpackChunkName: "component---src-templates-docs-js" */),
  "component---src-templates-es-js": () => import("./../../../src/templates/es.js" /* webpackChunkName: "component---src-templates-es-js" */),
  "component---src-templates-it-js": () => import("./../../../src/templates/it.js" /* webpackChunkName: "component---src-templates-it-js" */),
  "component---src-templates-por-js": () => import("./../../../src/templates/por.js" /* webpackChunkName: "component---src-templates-por-js" */)
}

