import React from 'react';
import styled from 'styled-components'

export default class Table extends React.Component {

    constructor(props){
      super(props);
      this.getHeader = this.getHeader.bind(this);
      this.getRowsData = this.getRowsData.bind(this);
      this.getKeys = this.getKeys.bind(this);

    }

    getAudio = (clipUrl) => {
        var audio = new Audio("/audio/" + clipUrl + ".mp3");
        return audio.play();
    };

    getKeys = function(){
      return Object.keys(this.props.data[0]);
    }
    
    getHeader = function(){
      var keys = this.getKeys();
      return keys.map((key, index)=>{
        return <th key={key}>{key.toUpperCase()}</th>
      })
    }
    
    getRowsData = function(){
      var items = this.props.data;
      var keys = this.getKeys();
      return items.map((row, index)=>{
        return (
        <tr key={index}>

            {keys.map((key, index)=>{
                    if (key === "audio") {
                    return (
                    <AudioCell key={row[key] + ".mp3"} onClick={() => this.getAudio(row[key])}>🔊</AudioCell>
                    )
                    } else return <td key={row[key]}>{row[key]}</td>
            })}

            </tr>
        )  
    })
    }
    
    render() {
        return (
          <div>
            <table>
            <thead>
              <tr>{this.getHeader()}</tr>
            </thead>
            <tbody>
              {this.getRowsData()}
            </tbody>
            </table>
          </div>
          
        );
    }
}
const AudioCell = styled.td`
cursor: pointer;
transition: 0.3s ease-in-out;
&:hover {
  background-color:#464646;
}
`